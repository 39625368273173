import React from 'react'
import Router from "next/router";
import {useRegionCountryCode} from "../hooks/region";
import {urlForCountryCode} from "../lib/region";
import {useSelector} from "react-redux";
import {RootState} from "../store/reducers/reducers";

export function withRedirectForNoCountryCode<P extends object>(WrappedComponent: React.ComponentType<P>) {
  return ({ ...props }) => {
    const regionInfo = useRegionCountryCode()
    const profileFetched = useSelector((state: RootState) => state.user.profileFetched)
    const tfaPassed = useSelector((state: RootState) => state.user.tfaPassed)
    const signedIn = useSelector((state: RootState) => state.user.signedIn)
    const preferredRegion = useSelector((state: RootState) => state.region.preferredRegion)

    React.useEffect(() => {
      if (regionInfo.isLoading || !profileFetched) {
        return
      }

      const { search , pathname } = window.location
      let query = ''
      if (search) {
        query = search.substring(1)
      }

      let url
      if (signedIn && tfaPassed && preferredRegion) {
        url = urlForCountryCode(preferredRegion, pathname, query)
      } else {
        url = urlForCountryCode(regionInfo.countryCode, pathname, query)
      }
      Router.replace(url)
    }, [profileFetched, tfaPassed, signedIn, regionInfo.countryCode, regionInfo.isLoading, preferredRegion])
    return <WrappedComponent {...props as P} />
  }
}