import HomePage, {getStaticPropsWithoutCountry} from './[countryCode]'
import {wrapper} from "../store/store";
import * as staticProps from '../lib/static_props'
import {withRedirectForNoCountryCode} from "../components/with_redirect_for_no_country_code";
import {Banner} from "../types/models/banner";

interface HomeProps {
  banners: Banner[];
}

function Home({ banners }: HomeProps) {
  return <HomePage
    banners={banners}
  />
}

export default withRedirectForNoCountryCode(Home)

export const getStaticProps = wrapper.getStaticProps(store => async () => {
  await staticProps.populateServerSideStore(store.dispatch)
  return getStaticPropsWithoutCountry()
})
