import urljoin from "url-join";
import Api from "../service/api";

export function urlForCountryCode(targetCountryCode: string, pathname: string, query?: string) {
  const pathParts = pathname.split('/')
  let search = ''
  if (query) {
    search = '?' + query
  }
  return urljoin('/', targetCountryCode, ...pathParts.slice(1)) + search
}

export function urlForReplacedCountryCode(targetCountryCode: string, pathname: string, query?: string) {
  const pathParts = pathname.split('/')
  pathParts[1] = targetCountryCode
  let search = ''
  if (query) {
    search = '?' + query
  }
  return urljoin('/', ...pathParts) + search
}

let getRegionsPromise: Promise<string[]> | undefined = undefined;
let getRegionsResult: string[] | undefined = undefined;
export async function getRegionsCached() {
  if (getRegionsResult) {
    return getRegionsResult
  }
  if (!getRegionsPromise) {
    getRegionsPromise = Api.region.getAll()
      .then(res => res.data.regions)
  }

  const result = await getRegionsPromise
  getRegionsResult = result
  return result
}